import axios from "axios";
import QS from "querystring";


// 请求域名
axios.defaults.baseURL = 'https://backend.akedo.fun/index.php/';
// axios.defaults.baseURL = 'http://192.168.1.201/index.php/';
axios.defaults.timeout = 10000;





export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

// export function destroy(url) {
//   return new Promise((resolve, reject) => {
//     axios
//       .delete(url)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.data);
//       });
//   });
// }

// export function post(url, params) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(url, params)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.data);
//       });
//   });
// }

// export function put(url, params) {
//   return new Promise((resolve, reject) => {
//     axios
//       .put(url, QS.stringify(params))
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.data);
//       });
//   });
// }
